"use client";
import CustomInput from "@/components/shared/CustomInput";
import CustomSelect from "@/components/shared/CustomSelect";
import InputOtp from "@/components/shared/InputOtp";
import MyLink from "@/components/shared/myLink/MyLink";
import AUTH from "@/redux/Auth";
import { useAppDispatch } from "@/redux/store";
import { Form, Formik } from "formik";
import Link from "next/link";
import { useRouter } from "next/navigation";
import React, { useState } from "react";

const index = () => {
  const dispatch = useAppDispatch();
  const router = useRouter();
  const [loading, setLoading] = useState(false);

  return (
    <div className=" container bg-[#323538] p-5 md:py-[30px] md:px-[40px] rounded-[20px] mx-4 w-full lg:min-w-[531px] lg:max-w-[550px] md:mx-auto ">
      <p className=" text-40 text-white font-[500] text-center mb-5">
        تسجيل الدخول
      </p>
      <Formik
        initialValues={{ username: "", password: "" }}
        onSubmit={async (values) => {
          setLoading(true);
          await dispatch(AUTH.thunks.doLogin({ data: values })).then((res) => {
            res.meta.requestStatus === "fulfilled" && router.push("/");
          });
          setLoading(false);
        }}
      >
        {(props) => (
          <Form className=" flex flex-col gap-5">
            <CustomInput
              type="text"
              name="username"
              placeholder="اسم المستخدم"
            />
            <CustomInput
              type="password"
              name="password"
              placeholder="الرقم السري"
              eye={true}
            />
            <p className=" text-12 text-[#BEC4E0]">
              نست كلمه السر{" "}
              <MyLink
                href={"/forgotPassword"}
                className=" font-[600] text-primaryColor"
              >
                اضغط هنا
              </MyLink>
            </p>

            <div className=" flex items-center justify-center gap-5">
              <button
                type="submit"
                className=" text-13 font-500 text-[#202225] bg-primaryColor rounded-[7px] py-2 px-5"
                disabled={loading}
              >
                {loading ? "جار التسجيل ..." : "تسجيل دخول"}
              </button>
              <MyLink
                href={"/signup"}
                className=" text-13 font-500 text-white py-2 px-5 border border-white
                rounded-[7px] bg-gradient-to-b from-[#32353827] to-[#32353866]"
              >
                انشاء حساب
              </MyLink>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default index;
